const ProductSchema = {
  description: "description",
  price: "price",
  store_name: "store_name",
  product_address: "product_address",
  image_uri: "image_uri",
  reported_by: "reported_by",
  reported_at: "reported_at",
  updated_at: "updated_at",
  updated_by: "updated_by",
  store_location: "store_location",
  store_location_lat: "store_location_lat",
  store_location_lng: "store_location_lng",
  currency: "currency",
  price_trending: "price_trending",
  votes: "votes",
};

const BroadcastMessage = {
  id: "id",
  message: "message",
  created_at: "created_at",
  response: "response",
  responded_at: "responded_at",
  device_token: "device_token",
};

export const UP_TREND = 1;
export const DOWN_TREND = 0;

const UserSchema = {
  update: "update",
  report: "report",
  impact: "impact",
  deleted: false,
  username: "username",
};

const DbNode = {
  product: "products",
  history: "history",
  counters: "counters",
  user: "users",
  broadcast: "broadcasts",
};

const StorageNode = {
  images: "images",
};

const Schema = {
  product_schema: ProductSchema,
  db_node: DbNode,
  storage_node: StorageNode,
  user_schema: UserSchema,
  broadcast_message: BroadcastMessage,
};

export default Schema;
