import firestoreRepo from "./firestore.repo";
class AbstractGeocoder {
  /**
   * @type {AbstractGeocoder}
   */
  nextCoder = undefined;

  async reverseCode(lat, lng) {}

  async forwardCode(address) {}
}

class OpenCageGeocoder extends AbstractGeocoder {
  #OPEN_CAGE = "https://api.opencagedata.com/geocode/v1/json?q=";
  #OPEN_CAGE_API_KEY = "open_cage_api_key";
  #envs;

  constructor(nextCoder) {
    super();

    /**
     * @type {AbstractGeocoder}
     */
    this.nextCoder = nextCoder;
    setTimeout(
      () =>
        firestoreRepo.findDoc("/configs/envs").then((envs) => {
          this.#envs = envs;
        }),
      500
    );
  }

  async reverseCode(lat, lng) {
    try {
      const url = `${this.#OPEN_CAGE}${lat}+${lng}&key=${
        this.#envs[this.#OPEN_CAGE_API_KEY]
      }`;
      const response = await fetch(url);
      const payload = await response.json();

      const { results } = payload;
      const humanAddress = results.map(({ formatted, confidence }) => ({
        address: formatted,
        confidence: Number.parseFloat(confidence),
        lat: lat,
        lng: lng,
      }));

      humanAddress.sort(
        (first, second) => first.confidence - second.confidence
      );
      return humanAddress[0];
    } catch (error) {
      if (this.nextCoder) return this.nextCoder.reverseCode(lat, lng);
      console.log("error in reverseCode", error);
      return null;
    }
  }

  async forwardCode(address) {
    try {
      const url = `${this.#OPEN_CAGE}${address}&key=${
        this.#envs[this.#OPEN_CAGE_API_KEY]
      }`;
      const response = await fetch(encodeURI(url));
      const payload = await response.json();

      const { results } = payload;
      const humanAddress = results.map(
        ({ formatted, confidence, geometry: { lat, lng } }) => ({
          addr: formatted,
          confidence: Number.parseFloat(confidence),
          lat: lat,
          lng: lng,
        })
      );

      humanAddress.sort(
        (first, second) => second.confidence - first.confidence
      );
      return { addrPayload: humanAddress[0], unique: humanAddress.length < 3 };
    } catch (error) {
      if (this.nextCoder) return this.nextCoder.forwardCode(address);
      console.log("error in forwardCode", error);
      return null;
    }
  }
}

class GeocoderContext extends AbstractGeocoder {
  /**
   * @type {AbstractGeocoder}
   */
  #head = undefined;

  constructor() {
    super();
    this.#head = new OpenCageGeocoder(undefined);
  }

  async reverseCode(lat, lng) {
    return this.#head.reverseCode(lat, lng);
  }

  async forwardCode(address) {
    return this.#head.forwardCode(address);
  }
}

class Geolocator {
  #context = { position: undefined };

  /**
   * @type {AbstractGeocoder}
   */
  #geocoderContext = undefined;

  constructor(geocoderContext) {
    this.#geocoderContext = geocoderContext;
  }

  async getPosition() {
    const promise = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return promise;
  }

  async getPlace() {
    try {
      const {
        coords: { latitude, longitude },
      } = await this.getPosition();
      const place = await this.#geocoderContext.reverseCode(
        latitude,
        longitude
      );
      return { place: place };
    } catch (error) {
      console.log("error in getPosition", error);
      return { error: error };
    }
  }

  async computeDistanceFromRef(lat, lng) {
    const position = {
      latitude: lat,
      longitude: lng,
    };

    if (this.#context.position) {
      return new Promise((resolve, reject) => {
        const distance = this.#computeDistance(
          this.#context.position,
          position
        );
        resolve(distance);
      });
    } else {
      return new Promise((resolve, reject) => {
        this.getPosition()
          .then(({ coords }) => {
            this.#context.position = coords;
            const distance = this.#computeDistance(coords, position);
            resolve(distance);
          })
          .catch((error) => reject(error));
      });
    }
  }

  #computeDistance(refCoords, coords) {
    // The math module contains a function named toRadians which converts from degrees to radians.
    const lon1 = (refCoords.longitude * Math.PI) / 180;
    const lon2 = (coords.longitude * Math.PI) / 180;
    const lat1 = (refCoords.latitude * Math.PI) / 180;
    const lat2 = (coords.latitude * Math.PI) / 180;

    // Haversine formula
    const dlon = lon2 - lon1;
    const dlat = lat2 - lat1;
    const a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    const c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 6371 for KM and 3956 for MI
    const earthRadius = 3956;

    // calculate the result
    const d = c * earthRadius;
    return d;
  }

  async geocode(address) {
    try {
      const payload = await this.#geocoderContext.forwardCode(address);
      return payload;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

const geolocator = new Geolocator(new GeocoderContext());
export default geolocator;
