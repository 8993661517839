import {
  Box,
  Chip,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import firestoreRepo from "../../api/firestore.repo";
import Schema from "../../api/schema";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";

export default function BroadcastItem({
  broadcast: { id, message, response },
}) {
  const [innerResponse, setResponse] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRespond = useCallback(
    (retry = 5) => {
      const { db_node } = Schema;
      setError(false);
      setLoading(true);
      firestoreRepo
        .updateDoc(`${db_node.broadcast}/${id}`, {
          response: innerResponse,
          responded_at: Date.now(),
        })
        .then(() => {
          setLoading(false);
          setResponse("");
        })
        .catch(() => {
          if (retry > 0) handleRespond(retry - 1);
          else {
            setError(true);
            setLoading(false)
          }
        });
    },
    [innerResponse]
  );

  const onTextChange = ({ target: { value } }) => {
    setResponse(value);
  };

  return (
    <Paper elevation={4} sx={{ padding: "8px", marginBottom: "8px" }}>
      <Box mt={"16px"}>
        <Chip label={message} />
      </Box>
      {response && (
        <Box mt={"8px"} align={"end"}>
          <Chip label={response} color={"secondary"} />
        </Box>
      )}
      <Stack direction={"row"} mt={"16px"}>
        <TextField
          label="Enter Response"
          variant="outlined"
          multiline
          onChange={onTextChange}
          sx={{ width: "80%" }}
          error={error}
          helperText={error ? "Unable to send your response" : null}
          value={innerResponse}
        />
        <LoadingButton
          loading={loading}
          onClick={handleRespond}
          sx={{ width: "20%", marginLeft: "8px" }}
          startIcon={<SendIcon />}
        >
          Send
        </LoadingButton>
      </Stack>
    </Paper>
  );
}
