import { useNavigate } from "react-router-dom";
import Schema from "../api/schema";
import Product from "./product";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import PriceFilter, {
  DEFAULT_PRICE_RANGE,
  PRICE_FILTER_ENABLED,
} from "./price.filter";
import { Search, SEARCH_ENABLED } from "./search";
import firestoreRepo from "../api/firestore.repo";
import { useCallback, useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { where } from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import RadiusFilter, {
  DEFAULT_RADIUS,
  RADIUS_FILTER_ENABLED,
} from "./radius.filter";
import geolocator from "../api/geolocation";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  CircularProgress,
  Fade,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import Profile from "./identity/profile";
import useAuth from "../api/useAuth";
import { MessageBox } from "./identity/messagebox";
import { LeaderBoard } from "./board";
import BroadcastContainer from "./broadcast/broadcast.container";

const { product_schema } = Schema;

export default function Home() {
  const navigator = useNavigate();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [priceRange, setPriceRange] = useState(DEFAULT_PRICE_RANGE);

  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const [locationError, setLocationError] = useState();
  const [searchResults, setSearchResults] = useState([]);

  const [user, setUser] = useState(null);
  const { authObserver } = useAuth();
  const locationErrorResetId = useRef();

  const [dataLength, setDataLength] = useState(10000);

  const findLow = () => {
    if(products.length == 0) return undefined
    return products[products.length - 1];
  };

  const filterFetch = () => {
    setLoading(true);
    let refPath;
    let low = findLow();
    if (low) {
      refPath = `${Schema.db_node.product}/${
        low[product_schema.product_address]
      }`;
    } else {
      refPath = `${Schema.db_node.product}/seed`;
    }

    firestoreRepo
      .getNextPage(refPath, dataLength, filters)
      .then(async (products) => {
        const filtered = await filterByRadius(products);
        setProducts(filterByQuery(filtered));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const filterByRadius = async (products) => {
    if (radius === DEFAULT_RADIUS) return products;
    const subset = [];
    for (let idx = 0; idx < products.length; idx++) {
      let product = products[idx];
      if (
        product[product_schema.store_location_lat] === null ||
        product[product_schema.store_location_lng] === null
      ) {
        continue;
      }

      try {
        const distance = await geolocator.computeDistanceFromRef(
          product[product_schema.store_location_lat],
          product[product_schema.store_location_lng]
        );

        if (distance <= radius) {
          subset.push(product);
        }
      } catch (error) {
        clearTimeout(locationErrorResetId.current);
        setLocationError(
          "Please turn on location services to use distance filter"
        );
        locationErrorResetId.current = setTimeout(setLocationError, 5000);

        return products;
      }
    }

    return subset;
  };

  const createProductMap = (products) => {
    const productMap = {};
    products.forEach(
      (product) =>
        (productMap[product[product_schema.product_address]] = product)
    );
    return productMap;
  };

  const filterByQuery = (newProducts) => {
    const updatedProducts = products.concat(newProducts)
    if (searchResults.length == 0) return updatedProducts;
    const subset = [];

    const productMap = createProductMap(updatedProducts);
    searchResults.forEach((result) => {
      if (productMap[result.id] !== undefined)
        subset.push(productMap[result.id]);
    });
    return subset;
  };

  const handleAdd = () => navigator("/add.view");
  const handleSignIn = () => navigator("/signin.view");
  const handleSignUp = () => navigator("/signup.view");

  const handlePriceChange = ([start, end]) => {
    setFilters([
      where(product_schema.price, ">=", start),
      where(product_schema.price, "<=", end),
    ]);
    setPriceRange([start, end]);
  };

  const handleSearchResult = ({ results }) => {
    setSearchResults(results);
  };

  const handleRadiusChange = (radius) => {
    setRadius(radius);
  };

  const loadDataLength = useCallback(() => {
    firestoreRepo.fetchProductCount().then(setDataLength);
  }, []);

  useEffect(filterFetch, [filters, radius, searchResults]);
  useEffect(() => authObserver(setUser), []);
  useEffect(loadDataLength, []);
  // useEffect(() => setHasMore(products.length < dataLength), [products.length])

  const onbudding = (
    <Paper className="shadow rounded p-3 m-3">
      <Stack alignContent={"center"} alignItems={"center"}>
        <Typography className="text-muted">
          Sign In to see how you're helping a bud!
        </Typography>
        <Stack
          sx={{
            marginTop: "10px",
          }}
          direction={"row"}
          spacing={2}
        >
          <Button onClick={handleSignIn} variant="contained">
            Sign In
          </Button>
          <Button onClick={handleSignUp} variant="contained">
            Sign Up
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );

  const profile = (
    <Paper className="shadow rounded p-3 m-3">
      <Profile />
    </Paper>
  );

  return (
    <>
      <Box className="add-btn">
        <Fab aria-label="add" onClick={handleAdd} color="primary">
          <AddIcon />
        </Fab>
      </Box>
      <div className="container rounded">
        <div className="row m-2">
          <div className="col-sm-12 col-md-4 col-lg-4">
            {user === null && (
              <MessageBox message={"Sign in to see your stats"} color="info" />
            )}
            {user === null && <Fade in={user === null}>{onbudding}</Fade>}
            {user && <Fade in={user !== null}>{profile}</Fade>}
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 shadow-lg text-center card border-0 bg-dark">
            <div className="d-flex justify-content-center m-2">
              <Fab>
                <img
                  src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
                  className="img-fluid"
                  arial-label="logo"
                />
              </Fab>
              <h1 className="display-4 text-white m-1">Shop Buddy</h1>
            </div>
            <h6 className="text-muted">Help a buddy save some $cheddar</h6>
            {SEARCH_ENABLED && <Search onSearchResult={handleSearchResult} />}
            {locationError && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {locationError}
              </Alert>
            )}
            {RADIUS_FILTER_ENABLED && (
              <RadiusFilter onRangeChange={handleRadiusChange} />
            )}
            {PRICE_FILTER_ENABLED && (
              <PriceFilter handlePriceChange={handlePriceChange} />
            )}
          </div>
          <div className="col-sm-12 col-md-2 col-lg-2"></div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <BroadcastContainer />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            {loading ? (
              <CircularProgress
                size={50}
                sx={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                }}
              />
            ) : (
              <InfiniteScroll
                dataLength={dataLength}
                hasMore={hasMore}
                next={filterFetch}
              >
                {products.map((product) => (
                  <Product
                    style={{}}
                    editEnabled
                    product={product}
                    key={product[product_schema.product_address]}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 m-3">
            <Stack direction={"row"} spacing={2}>
              <Avatar
                src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
                alt="logo"
              />
              <Button onClick={() => navigator("/terms.view")}>Terms</Button>
              <Button onClick={() => navigator("/privacy.view")}>
                Privacy
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
}
