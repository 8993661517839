import {
  Button,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Backdrop,
  Alert,
  AlertTitle,
  Avatar,
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Schema from "../api/schema";
import Product from "./product";
import firestoreRepo from "../api/firestore.repo";
import { useNavigate } from "react-router-dom";
import storageRepo from "../api/storage.repo";
import geolocator from "../api/geolocation";
import PlaceIcon from "@mui/icons-material/Place";
import ImageCapture from "./image.capture";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "@mui/material/Modal";
import useAuth from "../api/useAuth";
import { MessageBox } from "./identity/messagebox";
import luceneIngest from "./search";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const { product_schema } = Schema;
export const getDefaultCurrency = () => "USD";

export default function AddProduct() {
  const [products, setProducts] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");

  const [store, setStore] = useState("");
  const currencyRef = useRef();

  const [addMore, setAddMore] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigator = useNavigate();

  const [imageUri, setImageUri] = useState(null);
  const [place, setPlace] = useState({});
  const [goelocing, setGeolocing] = useState(false);

  const [address, setAddress] = useState("");
  const [noPrice, setNoPrice] = useState(false);
  const [noDesc, setNoDesc] = useState(false);

  const [beginCapture, setBeginCapture] = useState(false);
  const [locationError, setLocationError] = useState();
  const [imageCaptureError, setImageCaptureError] = useState();

  const [noStore, setNoStore] = useState(false);
  const imageCaptureErrorScheduleRef = useRef();
  const { authObserver } = useAuth();

  const [addressDiffer, setAddressDiffer] = useState(false);
  const [user, setUser] = useState(null);
  const [unique, setUnique] = useState(true);

  const [geocodeRes, setGeocodeRes] = useState(null);
  const handleUser = (user) => {
    setUser(user);
  };

  const handlePriceChange = ({ target: { value } }) => {
    setNoPrice(value === "");
    setPrice(Number.parseFloat(value));
  };

  const handleDescriptionChange = ({ target: { value } }) => {
    setNoDesc(value === "");
    setDescription(value);
  };

  const handleStoreChange = ({ target: { value } }) => {
    setNoStore(value == "");
    setStore(value);
  };

  const handleAdd = async () => {
    const {
      current: { value: currency },
    } = currencyRef;

    setNoPrice(price === "");
    setNoDesc(description === "");
    setNoStore(store === "");

    if (price === "" || description === "" || store === "") return;
    const product = { ...product_schema };
    product.price = price;

    product.description = description;
    product.currency = currency.length == 3 ? currency : getDefaultCurrency();
    product.store_location =
      address === "" ? "No address provided" : address.toLocaleUpperCase();

    product.store_name =
      store === "" ? "No store provided" : store.toLocaleUpperCase();
    product.reported_at = Date.now();
    product.image_uri = imageUri;

    product.reported_by = user?.uid || null;
    product.store_location_lat = place?.lat || null;
    product.store_location_lng = place?.lng || null;

    product.votes = 1
    product.updated_at = null
    product.updated_by = null

    if (address !== "") {
      if (geocodeRes == null || addressDiffer) {
        const { addrPayload } = await geolocator.geocode(address);
        const { addr, lat, lng } = addrPayload;
        product.store_location = addr.toLocaleUpperCase();

        product.store_location_lat = lat;
        product.store_location_lng = lng;

        setGeocodeRes(addrPayload);
        setAddressDiffer(false);
      } else {
        const { addr, lat, lng } = geocodeRes;
        product.store_location = addr.toLocaleUpperCase();

        product.store_location_lat = lat;
        product.store_location_lng = lng;
      }

      products.push(product);
      beginUploadOrWaitForMore(products);
      setProducts([...products]);
    } else {
      products.push(product);
      beginUploadOrWaitForMore(products);
      setProducts([...products]);
    }

    setPrice("");
    setDescription("");
  };

  const uploadProducts = async (products) => {
    setUploading(true);
    const { db_node, product_schema, storage_node } = Schema;
    firestoreRepo.incrementCount(
      `${db_node.counters}/${db_node.product}`,
      products.length
    );

    if (user !== null) {
      firestoreRepo.updateDoc(`${db_node.user}/${user.uid}`, {
        report: firestoreRepo.getIncrement(products.length),
      });
    }

    const productInserts = products.map(async (product) => {
      const docRef = firestoreRepo.generateDocRefForCollection(db_node.product);
      product[product_schema.product_address] = docRef.id;
      firestoreRepo.addToHistory({
        description: product[product_schema.description],
        price: product[product_schema.price],
        date: product[product_schema.reported_at],
      });

      if (product[product_schema.image_uri]) {
        const file = await storageRepo.readLocal(
          product[product_schema.image_uri]
        );

        URL.revokeObjectURL(product[product_schema.image_uri]);
        return storageRepo
          .writeFile(storage_node.images, docRef.id, file)
          .then((downloadUrl) => {
            product[product_schema.image_uri] = downloadUrl;
            return firestoreRepo.insertDoc(docRef, product);
          });
      }

      return firestoreRepo.insertDoc(docRef, product);
    });

    await Promise.allSettled(productInserts);
    luceneIngest(products);

    setTimeout(() => {
      setUploading(false);
      navigator("/");
    }, 5000);
  };

  const beginUploadOrWaitForMore = (products) => {
    if (addMore) {
      return;
    }

    uploadProducts(products);
  };

  const handleAddMore = ({ target: { checked } }) => {
    if (!checked && products.length > 0) {
      uploadProducts(products);
    }
    setAddMore(checked);
  };

  const handleImageCapture = (imageData) => {
    const blob = imageData.blob;
    const localUri = URL.createObjectURL(blob);
    URL.revokeObjectURL(imageUri);

    setImageUri(localUri);
    setBeginCapture(false);
  };

  const handleOnCapture = () => {
    setBeginCapture(true);
  };

  const handleLocationCapture = async () => {
    setGeolocing(true);
    const { place } = await geolocator.getPlace();
    setPlace(place);

    if (place === undefined) {
      setLocationError(
        "Failed get your position. Ensure you've allowed access to your location."
      );
      setTimeout(setLocationError, 5000);
    }
    setAddress(place?.address || "");
    setGeolocing(false);
  };

  const handleImageCaptureError = (error) => {
    setBeginCapture(false);
    setImageCaptureError(
      `${error.message}. Ensure you've allowed access to your camera.`
    );
    clearTimeout(imageCaptureErrorScheduleRef.current);

    imageCaptureErrorScheduleRef.current = setTimeout(
      setImageCaptureError,
      5000
    );
  };

  const handleAddressChange = ({ target: { value } }) => {
    setAddressDiffer(true);
    setAddress(value);
  };

  useEffect(() => authObserver(handleUser), []);

  return (
    <>
      {uploading && (
        <Backdrop
          open={uploading}
          sx={{
            zIndex: 1_000_000,
          }}
        >
          <CircularProgress
            size={50}
            sx={{
              position: "absolute",
              top: "35%",
              left: "50%",
            }}
          />
        </Backdrop>
      )}
      {beginCapture && (
        <Modal
          open={beginCapture}
          onClose={() => setBeginCapture(false)}
          aria-labelledby="image capture"
          aria-describedby="take a photo of the item you're purchasing"
        >
          <ImageCapture
            onCapture={handleImageCapture}
            onError={handleImageCaptureError}
          />
        </Modal>
      )}
      <div className="container rounded">
        <div className="row m-2">
          <div className="col-sm-0 col-md-4 col-lg-4">
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Fab onClick={() => navigator("/")} color="secondary" size="small">
              <ArrowBackIosIcon />
            </Fab>
          </div>
          <div className="col-sm-0 col-md-4 col-lg-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-0 col-md-0 col-lg-4"></div>
          <div className="col-sm-12 col-md-6 col-lg-4 m-1">
            {!user && (
              <MessageBox
                message={"Sign in to record your impact"}
                color="info"
              />
            )}
            {imageCaptureError && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {imageCaptureError}
              </Alert>
            )}
            <div className="d-flex justify-content-center">
              {imageUri && <img className="img-btn" src={imageUri} />}
              <Avatar
                onClick={handleOnCapture}
                sx={{ cursor: "pointer", marginTop: "8px" }}
              >
                <CameraAltIcon />
              </Avatar>
            </div>

            <div className="mt-3">
              <label htmlFor="description" className={"form-label"}>
                Description
              </label>
              <input
                onChange={handleDescriptionChange}
                type="text"
                className="form-control"
                id="description"
                defaultValue={null}
                placeholder="Old Spice spray, 708ml"
                required
                value={description}
              />
              {noDesc && (
                <div class="text-danger">Description is required.</div>
              )}
            </div>
            <div className="mt-3">
              <label htmlFor="price" className={"form-label"}>
                Price
              </label>
              <input
                onChange={handlePriceChange}
                type="number"
                className="form-control"
                id="price"
                defaultValue={null}
                placeholder="10.99"
                required
                value={price}
              />
              {noPrice && <div class="text-danger">Price is required.</div>}
            </div>
            <div className="mt-3">
              <label htmlFor="currency" className={"form-label"}>
                Currency
              </label>
              <input
                ref={currencyRef}
                type="text"
                className="form-control"
                id="currency"
                defaultValue={getDefaultCurrency()}
                size={3}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="store" className={"form-label"}>
                Store
              </label>
              <input
                onChange={handleStoreChange}
                type="text"
                className="form-control"
                id="store"
                placeholder="Walmart"
                value={store}
              />
              {noStore && <div class="text-danger">Store is required.</div>}
            </div>
            <div className="mt-3">
              {locationError && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {locationError}
                </Alert>
              )}
              {!unique && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  The address you entered has too many matches.
                </Alert>
              )}
              <div>
                <label htmlFor="location" className={"form-label"}>
                  Store address
                </label>
                {goelocing ? (
                  <CircularProgress size={20} className="ms-2" />
                ) : (
                  <Button onClick={handleLocationCapture}>
                    <PlaceIcon color="primary" />
                  </Button>
                )}
              </div>
              <input
                type="text"
                className="form-control"
                id="location"
                placeholder="1809 ST 54 FL, US"
                onChange={handleAddressChange}
                value={address}
              />
            </div>
            <div className="float-end mt-3">
              <Button variant="contained" onClick={handleAdd} color="primary">
                Add
              </Button>
            </div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleAddMore} color="primary" />}
                label="Add more"
              />
            </FormGroup>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            {products.map((product, idx) => (
              <Product
                style={{ class: "mt-3" }}
                product={product}
                key={`${product[product_schema.description]}#${idx}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
