import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../api/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, CircularProgress, Fab } from "@mui/material";
import { MessageBox } from "./messagebox";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const { signin } = useAuth();
  const navigate = useNavigate();

  const handleSuccess = (user) => {
    setLoading(false);
    navigate("/");
  };

  const handleOnSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    signin(email, password)
      .then(handleSuccess)
      .catch(({ code }) => {
        setLoading(false);
        if (code === "auth/user-not-found") {
          setErrorMessage(
            "Seems like you're quite mysterious. Let's sign you up!\uD83D\uDE00"
          );
        } else {
          setErrorMessage(
            "We have no clue what just happened. We might succeed if you try again. Please do \uD83D\uDE00"
          );
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3"> </div>
        <div className="col-md-6"></div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-sm-0 col-md-4 col-lg-4"></div>
        <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
          <div className="d-flex justify-content-center">
            <Fab onClick={() => navigate("/")}>
              <Avatar
                src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
                alt="Shop Buddy"
              />
            </Fab>
            <p className="display-6 m-2">Sign In</p>
          </div>
          <div className="shadow">
            <div className="card-body">
              <div className="mb-4">
                <p className="text-muted">
                  <small>Not a Bud yet?</small>
                  <Link className="small fw-bold ms-2" to={"/signup.view"}>
                    Sign Up
                  </Link>
                </p>
                <MessageBox message={errorMessage} color={"info"} />
              </div>
              <span className="clearfix"></span>
              <form onSubmit={(event) => handleOnSubmit(event)}>
                <div className="form-group mb-4">
                  <label className="form-control-label">Email</label>
                  <div className="input-group">
                    <input
                      required
                      onChange={(event) => setEmail(event.target.value)}
                      type="email"
                      name="email"
                      className="form-control"
                      id="input-email"
                      placeholder="bud@shopbuddy.com"
                    />
                  </div>
                </div>

                <div className="form-group mb-4">
                  <label className="form-control-label">Password</label>
                  <div className="input-group mb-4">
                    <input
                      required
                      onChange={(event) => setPassword(event.target.value)}
                      type="password"
                      name="password"
                      className="form-control"
                      id="input-password"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div className="text-center mb-2">
                  <Link
                    className="small"
                    sx={{ textTransform: "capitalize" }}
                    to={"/password.reset.view"}
                  >
                    Forgot Password
                  </Link>
                </div>
                <div className="text-center">
                  <LoadingButton
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    loading={loading}
                    type="submit"
                    className="w-50"
                    variant="contained"
                  >
                    Sign In
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-0 col-md-4 col-lg-4"></div>
      </div>
      <div className="row">
        <div className="col-md-3"> </div>
        <div className="col-md-6"></div>
        <div className="col-md-3"> </div>
      </div>
    </>
  );
}
