import { Box, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { createRef, useCallback } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 4,
};

const controlStyle = {
  position: "absolute",
  bottom: "5%",
  left: "50%",
  transform: "translate(-50%, -250%)",
};

const canvasHiddenStyle = {
  display: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 4,
};

const canvasVisibleStyle = {
  display: "block",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 4,
};

const captureBtn = {
  border: "1px solid white",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  background: "#f7473587",
  cursor: "pointer",
  transform: "translate(450%, -250%)",
};

function ImageCapture({ onCapture, onError }) {
  const tracks = useRef();
  const playerRef = createRef();
  const canvasRef = createRef();
  
  const [isCaptured, setCaptured] = useState(false);
  const [streaming, setStreaming] = useState(false);

  useEffect(() => {
    let timeout;
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: { facingMode: "environment" },
      })
      .then((stream) => {
        playerRef.current.srcObject = stream;
        tracks.current = stream.getTracks();
        timeout = setTimeout(() => setStreaming(true), 2000);
      })
      .catch((error) => {
        onError && onError(error);
      });
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [onError]);

  useEffect(() => {
    return () => {
      // Stop the camera stream
      if (tracks.current) {
        tracks.current[0].stop();
      }
    };
  }, []);

  const useImage = () => {
    if (onCapture) {
      const webPData = canvasRef.current.toDataURL("image/webp");
      canvasRef.current.toBlob((blob) => {
        onCapture({
          blob,
          webP: webPData,
          file: new File([webPData], `${new Date().getTime()}.png`),
        });
      });
    }
  };

  const retake = () => {
    setCaptured(false);
  };

  const captureImage = useCallback(() => {
    const imageWidth = playerRef.current.offsetWidth;
    const imageHeight = playerRef.current.offsetHeight;
    [canvasRef.current.width, canvasRef.current.height] = [
      imageWidth,
      imageHeight,
    ];
    const context = canvasRef.current.getContext("2d");
    context.drawImage(playerRef.current, 0, 0, imageWidth, imageHeight);
    setCaptured(true);
  }, [onCapture, canvasRef, playerRef]);

  return (
    <Box sx={style}>
      <video ref={playerRef} autoPlay width={500} playsInline></video>
      {streaming && (
        <>
          <canvas
            style={isCaptured ? canvasVisibleStyle : canvasHiddenStyle}
            ref={canvasRef}
          />
          {!isCaptured && <Box style={captureBtn} onClick={captureImage}></Box>}
          {isCaptured && (
            <>
              <Box sx={controlStyle}>
                <Button
                  sx={{
                    marginRight: "20px",
                  }}
                  variant="contained"
                  onClick={useImage}
                >
                  Use Image
                </Button>
                <Button
                  sx={{
                    marginLeft: "20px",
                  }}
                  variant="contained"
                  onClick={retake}
                >
                  Retake
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default ImageCapture;
