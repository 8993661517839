import { db } from "..";
import {
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc as fbUpdate,
  query,
  onSnapshot,
  orderBy,
  increment,
  limit,
  startAfter,
} from "firebase/firestore";
import Schema from "./schema";

class FireStoreRepository {
  #filter_context = {};

  get filterContext() {
    return this.#filter_context;
  }

  set filterContext(value) {
    this.#filter_context = value;
  }

  async findDoc(path) {
    try {
      const snapshot = await this.findDocSnapshot(path);
      return snapshot.data() || {};
    } catch (error) {
      console.log("error findDoc", error);
      return {};
    }
  }

  async findDocSnapshot(path) {
    try {
      const docRef = doc(db, path);
      const snapshot = await getDoc(docRef);
      return snapshot;
    } catch (error) {
      console.log("error findDocSnapshot", error);
      return {};
    }
  }

  async findAllDoc(path, ordering = { field: "report", order: "desc" }) {
    try {
      const collectionRef = collection(db, path);
      const query_ = query(
        collectionRef,
        orderBy(ordering.field, ordering.order)
      );
      const querySnapshot = await getDocs(query_);

      return querySnapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.log("error findAllDoc", error);
      return "";
    }
  }

  subscribe(
    path,
    snapshotChangeListener,
    ordering = { field: "votes", order: "desc" }
  ) {
    try {
      const collectionRef = collection(db, path);
      const query_ = query(
        collectionRef,
        orderBy(ordering.field, ordering.order)
      );

      const unsubscribe = onSnapshot(query_, {
        next: (snapshot) => {
          const docs = snapshot.docs.map((doc) => doc.data());
          snapshotChangeListener(docs);
        },
        error: (error) => {
          console.log("Error", error);
        },
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
      return () => {};
    }
  }

  async insertDoc(docRef, data) {
    try {
      await setDoc(docRef, data);
      return docRef.id;
    } catch (error) {
      console.log("error insertDoc", error);
      return "";
    }
  }

  async addToHistory(item) {
    try {
      const history = Schema.db_node.history;
      const { product_schema } = Schema;
      const ref = db
        .collection(`${history}/${item[product_schema.description].trim()}/${history}`)
        .doc();

      return setDoc(ref, item);
    } catch (error) {
      console.log("error insertDoc", error);
      return "";
    }
  }

  async deleteDoc(path) {
    try {
      await deleteDoc(doc(db, path));
      return true;
    } catch (error) {
      console.log("error deleteDoc", error);
      return false;
    }
  }

  async updateDoc(path, data) {
    try {
      const docRef = doc(db, path);
      await fbUpdate(docRef, data);
      return true;
    } catch (error) {
      console.log("error updateDoc", error);
      return false;
    }
  }

  attachedListener(path, callback) {
    const query_ = query(collection(db, path));
    const unsubscribe = onSnapshot(query_, (querySnapshot) => {
      const data = querySnapshot.map((doc) => doc.data());
      callback(data);
    });

    return unsubscribe;
  }

  generateDocRefForCollection(path) {
    return doc(collection(db, path));
  }

  getDocRef(collection, docId) {
    return doc(db, collection, docId);
  }

  async getNextPage(startPath, size, filters) {
    try {
      const query_ = query(
        collection(db, Schema.db_node.product),
        orderBy(Schema.product_schema.votes, "desc"),
        startAfter(await this.findDocSnapshot(startPath)),
        limit(size),
        ...filters
      );

      const productSnapshots = await getDocs(query_);
      const products = productSnapshots.docs.map((doc) => {
        const data = doc.data();
        return data;
      });

      return products;
    } catch (error) {
      console.log("error getNexPage", error);
      return [];
    }
  }

  async incrementCount(path, value) {
    try {
      const ref = doc(db, path);
      await fbUpdate(ref, { count: increment(value) });
    } catch (error) {
      console.log("error incrementCount", error);
    }
  }

  getIncrement(value) {
    return increment(value);
  }

  async fetchProductCount() {
    try {
      const ref = doc(db, "counters/products");
      const counter = await getDoc(ref);
      return counter.data().count;
    } catch (error) {
      console.log("error fetchProductCount", error);
      return 100;
    }
  }
}

const firestoreRepo = new FireStoreRepository();
export default firestoreRepo;
