import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import firestoreRepo from "../../api/firestore.repo";
import Schema from "../../api/schema";
import BroadcastItem from "./item";

export const BROADCAST_ENABLED = false;

export default function BroadcastContainer() {
  const [requests, setRequests] = useState([]);
  const [top, setTop] = useState(1);
  const timerRef = useRef();

  const loopCast = () => setTop((top + 1) % requests.length);

  useEffect(() => {
    const { db_node } = Schema;
    return firestoreRepo.subscribe(db_node.broadcast, (requests) => {
      setRequests(requests);
      setTop(0);
    });
  }, []);

  useEffect(() => {
    clearInterval(timerRef.current);
    timerRef.current = setTimeout(loopCast, 10000);
  }, [top]);

  return (
    <>
      {requests[top] && (
        <Box
          onMouseEnter={() => clearTimeout(timerRef.current)}
          onMouseLeave={loopCast}
        >
          <Typography variant="h3" sx={{ marginBottom: "8px" }}>
            Buddy Casts
          </Typography>
          <BroadcastItem key={requests[top].id} broadcast={requests[top]} />
        </Box>
      )}
    </>
  );
}
