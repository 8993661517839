import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { getDefaultCurrency } from "./add.product";
import { useRef } from "react";
import { Typography } from "@mui/material";

const MARKS = [
  {
    value: 100,
    label: `${getDefaultCurrency()} 100`,
  },

  {
    value: 500,
    label: `${getDefaultCurrency()} 500`,
  },

  {
    value: 900,
    label: `${getDefaultCurrency()} 900`,
  },
];

const MAX = 1_000;

export const PRICE_FILTER_ENABLED = true;
export const DEFAULT_PRICE_RANGE = [0, 1000]
export default function PriceFilter({ handlePriceChange}) {
  const rangeChangeSchedulerRef = useRef();

  const onChange = (_, newValue) => {
    clearTimeout(rangeChangeSchedulerRef.current);
    rangeChangeSchedulerRef.current = setTimeout(() => {
      handlePriceChange && handlePriceChange(newValue);
    }, 500);
  };

  return (
    <Box className="ms-3 me-3 mt-3 text-light">
      <Typography>Price Range</Typography>
      <Slider
        sx={{
          "& .MuiSlider-markLabel": { color: "white" },
        }}
        defaultValue={DEFAULT_PRICE_RANGE}
        color="primary"
        getAriaLabel={() => "Price range"}
        onChange={onChange}
        valueLabelDisplay="auto"
        disableSwap
        max={MAX}
        marks={MARKS}
      />
    </Box>
  );
}
