import { debounce } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { isDev } from "..";
import Schema from "../api/schema";

export const SEARCH_ENABLED = true;
export const SEARCH_API_ENDPOINT = isDev()
  ? "http://localhost:8080"
  : "https://search-dot-shopbuddy-a9b45.uc.r.appspot.com";

export function Search({ onSearchResult }) {
  const querySchedulerRef = useRef();
  const queryHandler = useCallback(
    debounce((query) => {
      fetch(`${SEARCH_API_ENDPOINT}/api/v1/search?query=${query}`)
        .then((resp) => resp.json())
        .then((payload) => onSearchResult && onSearchResult(payload))
        .catch((_error) => onSearchResult && onSearchResult([]));
    }, 100),
    [onSearchResult]
  );

  const onChange = ({ target: { value: query } }) => {
    clearTimeout(querySchedulerRef.current);
    queryHandler(query);
  };

  useEffect(() => {
    fetch(`${SEARCH_API_ENDPOINT}/_ah/start`)
      .then((resp) => resp)
      .catch((error) => error);
  },[]);

  return (
    <input
      type={"search"}
      placeholder={"Search product"}
      className={"form-control mt-3 ml-3 mb-3"}
      onChange={onChange}
    />
  );
}

export default function luceneIngest(products) {
  const { product_schema } = Schema;
  const lucene_payload = products.map((product) => ({
    id: product[product_schema.product_address],
    description: product[product_schema.description],
    location: product[product_schema.store_location],
    store: product[product_schema.store_name],
    price: product[product_schema.price],
  }));

  const payload = { payload: lucene_payload };
  return fetch(SEARCH_API_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
