import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../api/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, CircularProgress, Fab } from "@mui/material";
import { MessageBox } from "./messagebox";
import firestoreRepo from "../../api/firestore.repo";
import Schema from "../../api/schema";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { signup } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();

  const handleSuccess = async (user) => {
    const { user_schema, db_node } = Schema;
    const data = { ...user_schema };
    data.impact = data.report = data.update = 0;
    
    data.username = user.email.split("@")[0]
    firestoreRepo.insertDoc(
      firestoreRepo.getDocRef(db_node.user, user.uid),
      data
    );
    setLoading(false);

    navigate("/");
  };

  const handleOnSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    signup(email, password)
      .then(handleSuccess)
      .catch(({ code }) => {
        setLoading(false);
        if ("auth/email-already-in-use" === code) {
          setErrorMessage("Email is already registered. Please sign in");
        } else {
          setErrorMessage("Sorry Bud. Please try again!");
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3"> </div>
        <div className="col-md-6"></div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-sm-0 col-md-4 col-lg-4"></div>
        <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
          <div className="d-flex justify-content-center">
            <Fab onClick={() => navigate("/")}>
              <Avatar
                src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
                alt="Shop Buddy"
              />
            </Fab>
            <p className="display-6 m-2">Sign Up</p>
          </div>
          <div className="shadow">
            <div className="card-body">
              <div className="mb-4">
                <p className="text-muted">
                  <small>Already a Bud?</small>
                  <Link className="small fw-bold ms-2" to={"/signin.view"}>
                    Sign In
                  </Link>
                </p>
                <MessageBox message={errorMessage} color={"error"} />
              </div>
              <span className="clearfix"></span>
              <form onSubmit={(event) => handleOnSubmit(event)}>
                <div className="form-group mb-4">
                  <label className="form-control-label">Email</label>
                  <div className="input-group">
                    <input
                      required
                      onChange={(event) => setEmail(event.target.value)}
                      type="email"
                      name="email"
                      className="form-control"
                      id="input-email"
                      placeholder="bud@shopbuddy.com"
                    ></input>
                  </div>
                </div>

                <div className="form-group mb-4">
                  <label className="form-control-label">Password</label>
                  <div className="input-group">
                    <input
                      required
                      onChange={(event) => setPassword(event.target.value)}
                      type="password"
                      name="password"
                      className="form-control"
                      id="input-password"
                      placeholder="Password"
                    ></input>
                  </div>
                </div>
                <div className="mb-4 text-center">
                  <LoadingButton
                    loadingIndicator={<CircularProgress size={20} />}
                    loading={loading}
                    type="submit"
                    className="w-50"
                    variant="contained"
                  >
                    Register
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-0 col-md-4 col-lg-4"></div>
      </div>
      <div className="row">
        <div className="col-md-3"> </div>
        <div className="col-md-6"></div>
        <div className="col-md-3"> </div>
      </div>
    </>
  );
}
