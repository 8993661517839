import React, { useState } from "react";

export function Toast({ message, onDismiss }) {
  const [show, setShow] = useState(true);

  const handleDismiss = () => {
    onDismiss();
    setShow(false);
  };

  return (
    <>
      <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <img
            src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
            alt="Shop Buddy"
            class="rounded me-2"
          />
          <strong class="me-auto">Shop Buddy</strong>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={handleDismiss}
          ></button>
        </div>
        <div class="toast-body">{message}</div>
      </div>
    </>
  );
}
