import { Avatar, Button, debounce, Typography } from "@mui/material";
import Schema, { DOWN_TREND, UP_TREND } from "../api/schema";
import { useCallback, useEffect, useState } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import PlaceIcon from "@mui/icons-material/Place";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import firestoreRepo from "../api/firestore.repo";
import useAuth from "../api/useAuth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const { product_schema, db_node } = Schema;

export default function Product({ product, style, editEnabled }) {
  const [editing, setEditing] = useState(false);
  const [price, setPrice] = useState(product[product_schema.price]);
  const [user, setUser] = useState();
  const [votes, setVotes] = useState(product[product_schema.votes] || 0);

  const [priceTrend, setTrend] = useState(
    Number.parseInt(
      product[product_schema.price_trending] == undefined
        ? -1
        : product[product_schema.price_trending]
    )
  );
  const { authObserver } = useAuth();

  const handlePriceEdit = () => {
    if (editing && price > 0) {
      const { db_node } = Schema;
      const timestamp = Date.now();
      firestoreRepo.updateDoc(
        `${db_node.product}/${product[product_schema.product_address]}`,
        {
          price: price,
          updated_by: user?.uid || null,
          updated_at: timestamp,
          price_trending: priceTrend,
        }
      );

      firestoreRepo.addToHistory({
        description: product[product_schema.description],
        price: price,
        date: timestamp,
      });
      product[product_schema.price] = price;
      if (user !== null) {
        firestoreRepo.updateDoc(`${db_node.user}/${user.uid}`, {
          update: firestoreRepo.getIncrement(1),
        });
      }
    }
    setEditing(!editing);
  };

  const handlePriceChange = ({ target: { value } }) => {
    const newPrice = Number.parseFloat(value);
    setPrice(newPrice);
    computePriceTrend(newPrice);
  };

  const computePriceTrend = useCallback((newPrice) => {
    setTrend(newPrice > price ? UP_TREND : DOWN_TREND);
  }, [price]);

  const handleUpVote = debounce(() => {
    setVotes(votes + 1);
  }, 100);

  const handleDownVote = debounce(() => {
    setVotes(votes - 1);
  }, 100);

  useEffect(() => authObserver(setUser), []);
  useEffect(() => {
    firestoreRepo.updateDoc(
      `${db_node.product}/${product[product_schema.product_address]}`,
      { votes: votes }
    );
  }, [votes]);

  return (
    <div style={style} className="d-flex flex-column m-2">
      <img
        className="img-fluid"
        alt="product image"
        src={
          product[product_schema.image_uri] ||
          `${process.env.PUBLIC_URL}/featureproxy.png`
        }
      />
      <div className="d-flex justify-content-md-between p-2" id="first-half">
        <div>
          <div className="d-flex mt-1 mb-1">
            <p className="fs-4">{product[product_schema.description]}</p>
          </div>
          <div className="d-flex mt-1 mb-1">
            <PaidIcon color="primary" />
            <div className="d-flex">
              <div className="d-flex">
                {editing ? (
                  <input
                    type={"text"}
                    className="form-control"
                    onChange={handlePriceChange}
                  />
                ) : (
                  <p className="ms-1 fs-4 fw-bold">
                    {product[product_schema.price]}
                  </p>
                )}
              </div>
              <p className="ms-1 fw-bold">{product[product_schema.currency]}</p>
              {priceTrend != -1 &&
                (priceTrend == 1 ? (
                  <ArrowDropUpIcon color="error" />
                ) : (
                  <ArrowDropDownIcon color="primary" />
                ))}
            </div>
          </div>
          <div className="d-flex mt-1 mb-1">
            <ShoppingCartIcon color="primary" />
            <p className="ms-1 fs-5">{product[product_schema.store_name]}</p>
          </div>
          <div className="d-flex mt-1 mb-1">
            <PlaceIcon color="primary" />
            <p className="ms-1">{product[product_schema.store_location]}</p>
          </div>
          <div className="d-flex mt-1 mb-1">
            {editEnabled && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePriceEdit}
                sx={{ marginLeft: "5px" }}
              >
                {editing ? "Done" : "Wrong Price"}
              </Button>
            )}
            <div className="d-flex">
              <Button onClick={handleUpVote}>
                <ArrowCircleUpIcon />
              </Button>
              <Avatar>
                <Typography>{votes}</Typography>
              </Avatar>
              <Button onClick={handleDownVote}>
                <ArrowCircleDownIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
