import { Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";

const getUnit = () => "mi";
const MAX = 25;
const MIN = 1;
const MARKS = [
  {
    value: 5,
    label: `5 ${getUnit()}`,
  },
  {
    value: 15,
    label: `15 ${getUnit()}`,
  },
  {
    value: 25,
    label: `25 ${getUnit()}`,
  },
];
export const DEFAULT_RADIUS = 25
export const RADIUS_FILTER_ENABLED = true;
export default function RadiusFilter({ onRangeChange }) {
  const radiusChangeSchedulerRef = useRef();

  const onChange = (_, value) => {
    clearTimeout(radiusChangeSchedulerRef.current);
    radiusChangeSchedulerRef.current = setTimeout(() => {
      onRangeChange && onRangeChange(value);
    }, 500);
  };

  return (
    <Box className="ms-3 me-3 mt-2 text-light">
      <Typography>Distance ({getUnit()})</Typography>
      <Slider
        defaultValue={DEFAULT_RADIUS}
        onChange={onChange}
        color="primary"
        sx={{
          "& .MuiSlider-markLabel": { color: "white" },
        }}
        valueLabelDisplay="auto"
        min={MIN}
        max={MAX}
        marks={MARKS}
      />
    </Box>
  );
}
