import {
  uploadBytes,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "..";

class StorageRepository {
  async writeFile(node, fileId, file) {
    try {
      const fileRef = ref(storage, `${node}/${fileId}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);

      return url;
    } catch (error) {
      console.log("error writeFile", error);
      return null;
    }
  }

  async deleteFile(node, fileId) {
    try {
      const fileRef = ref(storage, `${node}/${fileId}`);
      await deleteObject(fileRef);
      return true;
    } catch (error) {
      console.log("error deleteFile", error);
      return false;
    }
  }

  async readLocal(url) {
    try {
      const response = await fetch(url);
      return response.blob();
    } catch (error) {
      console.log("error readLocal", error);
      return undefined;
    }
  }
}

const storageRepo = new StorageRepository();
export default storageRepo;
