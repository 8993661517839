import {
  Avatar,
  Chip,
  Modal,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import firestoreRepo from "../../api/firestore.repo";
import Schema from "../../api/schema";
import useAuth from "../../api/useAuth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Logout from "@mui/icons-material/Logout";
import { MessageBox } from "./messagebox";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "16px",
  maxWidth: "500px",
};

function CastModal({ isOpen, onClose }) {
  const [text, setText] = useState(null);
  const { db_node, broadcast_message } = Schema;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleTextChange = ({ target: { value: text } }) => {
    setText(text);
  };

  const handleCast = () => {
    if (text) {
      setLoading(true)
      const docRef = firestoreRepo.generateDocRefForCollection(
        db_node.broadcast
      );

      const message = { ...broadcast_message };
      message.created_at = Date.now();
      message.id = docRef.id;

      message.message = text;
      message.responded_at = message.response = null;
      message.device_token = null
      
      firestoreRepo
        .insertDoc(docRef, message)
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch(() => {
          setError(true);
        });
    } else {
      onClose();
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="Ask a question about something you want to buy."
    >
      <Paper style={style}>
        <TextField
          onChange={handleTextChange}
          label={"Enter message"}
          multiline
          sx={{ width: "100%" }}
          error={error}
          helperText={error ? "Failed to send" : null}
        />

        <LoadingButton
          onClick={handleCast}
          sx={{ margin: "8px", width: "25%", marginLeft: "75%" }}
          variant={"contained"}
          loading={loading}
        >
          Cast
        </LoadingButton>
      </Paper>
    </Modal>
  );
}

export default function Profile() {
  const { authObserver, signout, deleteAccount } = useAuth();
  const [user, setUser] = useState(null);
  const [data, setData] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const { user_schema, db_node } = Schema;
  const open = Boolean(anchorEl);

  const [deleteError, setDeleteError] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const handleSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCast = () => {
    setOpen(!isOpen);
  };

  useEffect(() => authObserver(loadData), []);

  function loadData(user) {
    setUser(user);
    user && firestoreRepo.findDoc(`${db_node.user}/${user.uid}`).then(setData);
  }

  const handleDelete = async () => {
    try {
      await firestoreRepo.updateDoc(`${db_node.user}/${user.uid}`, {
        deleted: true,
      });
      await deleteAccount(user);
      signout();
    } catch (error) {
      const { code } = error;
      if (code === "auth/requires-recent-login") {
        setDeleteError(
          "Please Bud out and sign back in to perform this action"
        );
      } else {
        setDeleteError("Unable to delete your account. Please try again.");
      }

      setTimeout(setDeleteError, 5000);
    }
  };

  return (
    <>
      <Stack direction={"column"} alignItems="center" spacing={2} p={"16px"}>
        <Tooltip title="Settings">
          <IconButton
            onClick={handleSettings}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar src={"null"} alt={data.username?.toUpperCase()} />
          </IconButton>
        </Tooltip>
        {deleteError && <MessageBox message={deleteError} color="error" />}
        <Typography>{user && (data.username || user.email)}</Typography>
        <Stack direction={"row"} spacing={1}>
          <Typography>Impact</Typography>
          <Chip
            size="small"
            label={`${data[user_schema.impact] || 0}`}
            variant="contained"
            color="primary"
          />
        </Stack>
        <div className="d-flex flex-lg-row flex-md-column flex-sm-column align-items-sm-center justify-content-lg-evenly">
          <Stack
            m={"8px"}
            alignContent={"center"}
            direction={"row"}
            spacing={1}
          >
            <Chip
              size="small"
              label={`${data[user_schema.report] || 0}`}
              variant="contained"
              color="primary"
            />
            <Typography>Reports</Typography>
          </Stack>
          <Stack m={"8px"} direction={"row"} spacing={1}>
            <Chip
              size="small"
              label={`${data[user_schema.update] || 0}`}
              variant="contained"
              color="primary"
            />
            <Typography>Updates</Typography>
          </Stack>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleCast}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            Bud Cast
          </MenuItem>
          <MenuItem onClick={signout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Bud Out
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Bud Delete
          </MenuItem>
        </Menu>
        <CastModal isOpen={isOpen} onClose={handleCast} />
      </Stack>
    </>
  );
}
