import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AddProduct from "./components/add.product";
import Home from "./components/home";
import PasswordReset from "./components/identity/password.reset";
import SignIn from "./components/identity/signin";
import SignUp from "./components/identity/signup";
import Privacy from "./components/privacy";
import Terms from "./components/terms";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#6dd397",
        main: "#38a169",
        dark: "#00713e",
        contrastText: "#fff",
      },
      secondary: {
        light: "#6dd397",
        main: "#00BE68",
        dark: "#00713e",
        contrastText: "#fff",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route exact path="add.view" element={<AddProduct />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="signin.view" element={<SignIn />} />
          <Route exact path="signup.view" element={<SignUp />} />
          <Route exact path="password.reset.view" element={<PasswordReset />} />
          <Route exact path="terms.view" element={<Terms />} />
          <Route exact path="privacy.view" element={<Privacy />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
