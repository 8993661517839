import { useState } from 'react';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updatePassword, sendPasswordResetEmail, deleteUser } from "firebase/auth";
import { auth } from '..';


export default function useAuth() {
    const [user, setUser] = useState(auth.currentUser)

    const signup = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
            .then(cred => cred.user)
    }

    const signin = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then(cred => cred.user)
    }

    const signout = () => {
        signOut(auth)
        setUser(null)
    }

    const authObserver = (func) => {
        return onAuthStateChanged(auth, user => {
            func((user && user.user) || (auth && auth.currentUser))
        })
    }

    const updatePass = (newPassword0, newPassword1) => {
        if (newPassword0 === newPassword1) {
            return updatePassword(user, newPassword1)
        }

        return Promise.reject("Passwords don't match")
    }

    const resetPassword = email => {
        return sendPasswordResetEmail(auth, email)
    }

    const deleteAccount = async(user) => {
        return deleteUser(user)
    }

    return {
        user,
        setUser,
        signin,
        signup,
        signout,
        authObserver,
        updatePass,
        resetPassword,
        deleteAccount
    }
}
