import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';

export function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

const firebaseConfig = {
  apiKey: "AIzaSyBJ-oTYu7nf7vY-Di0n2zX9F_Mex9_yOeY",
  authDomain: "shopbuddy-a9b45.firebaseapp.com",
  projectId: "shopbuddy-a9b45",
  storageBucket: "shopbuddy-a9b45.appspot.com",
  messagingSenderId: "401198556880",
  appId: "1:401198556880:web:f2881885486b41c7bd20d5",
  measurementId: "G-W6Y067TNPK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)

getAnalytics(app);
enableIndexedDbPersistence(db)

export const storage = getStorage(app);
export const appCheck = !isDev() && initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdH-kohAAAAAAGKvjr1ODULkFvMsy497jdF3g9r'),
  isTokenAutoRefreshEnabled: true,
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
