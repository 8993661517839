import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../api/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress, Avatar, Fab } from "@mui/material";
import { Toast } from "./toast";

export default function PasswordReset() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [showToast, setShowToast] = useState(false);
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const onDismiss = () => {
    navigate("/signin.view");
  };

  const handleOnSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    resetPassword(email)
      .then(() => setShowToast(true))
      .catch(() => {
        setLoading(false);
        setShowToast(true);
      });
  };

  return (
    <>
      <div className="row m-5">
        <div className="col-md-3"> </div>
        <div className="col-md-6"></div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-sm-0 col-md-4 col-lg-4"></div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="d-flex justify-content-center">
            <Fab onClick={() => navigate("/")}>
              <Avatar
                src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
                alt="Shop Buddy"
              />
            </Fab>
            <p className="display-6">Shop Buddy</p>
          </div>
          <div className="shadow">
            <div className="card-body">
              <div>
                <h6 className="display-6">Password Reset</h6>
              </div>
              <span className="clearfix"></span>
              <form onSubmit={(event) => handleOnSubmit(event)}>
                <div className="form-group mb-4">
                  <label className="form-control-label">Email</label>
                  <div className="input-group">
                    <input
                      required
                      onChange={(event) => setEmail(event.target.value)}
                      type="email"
                      name="email"
                      className="form-control"
                      id="input-email"
                      placeholder="name@example.com"
                    />
                  </div>
                </div>
                <div className="text-center">
                  <LoadingButton
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    loading={loading}
                    type="submit"
                    className="w-50"
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Reset Password
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-sm-0 col-md-4 col-lg-4"></div>
      </div>
      <div className="row">
        <div className="col-sm-0 col-md-2 col-lg-3"> </div>
        <div className="col-sm-12 col-md-8 col-lg-6">
          {showToast && (
            <Toast
              message={
                "A password reset email will be sent to the email if it's registered."
              }
              onDismiss={onDismiss}
            />
          )}
        </div>
        <div className="col-sm-0 col-md-2 col-lg-3"> </div>
      </div>
    </>
  );
}
